import { useAxios } from '@/hooks/useAxios'

const request = useAxios()

// 用户列表
export const list_user = (data) => {
    return request.post({
        url: '/auth/user/list_user',
        data,
    })
}

// 用户详情
export const user_info = (data) => {
  return request.post({
      url: '/auth/user/user_info',
      data,
  })
}

// 初始化密码
export const init_password = (data) => {
  return request.post({
      url: '/auth/user/initialize_password',
      data,
  })
}

// 修改用户信息
export const update_user = (data) => {
  return request.post({
      url: '/auth/user/update_user',
      data,
  })
}

// 禁用用户
export const ban_user = (data) => {
  return request.post({
      url: '/auth/user/ban_user',
      data,
  })
}

// 解禁用户
export const unban_user = (data) => {
  return request.post({
      url: '/auth/user/unban_user',
      data,
  })
}

// 新增用户
export const add_user = (data) => {
  return request.post({
      url: '/auth/user/add_user',
      data,
  })
}

// 角色列表
export const list_character = () => {
  return request.get({
      url: '/auth/character/list_character'
  })
}

// 添加角色
export const add_character = (data) => {
  return request.post({
      url: '/auth/character/add_character',
      data,
  })
}

// 更新角色名称
export const update_character = (data) => {
  return request.post({
      url: '/auth/character/update_character',
      data,
  })
}

// 删除角色
export const delete_character = (data) => {
  return request.post({
      url: '/auth/character/delete_character',
      data,
  })
}

// 删除角色
export const get_detail = (data) => {
  return request.post({
      url: '/auth/character/get_detail',
      data,
  })
}

// 更新权限
export const update_auth = (data) => {
  return request.post({
      url: '/auth/character/update_auth',
      data,
  })
}

// 获得登录列表
export const list_login = (data) => {
  return request.post({
      url: '/auth/log/list_login',
      data,
  })
}

// 信息列表权限
export const list_message = (data) => {
  return request.post({
      url: '/auth/log/list_message',
      data,
  })
}

// 操作列表权限
export const list_operate = (data) => {
  return request.post({
      url: '/auth/log/list_operate',
      data,
  })
}