<template>
    <div style="margin:0 10px">
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
            <el-tab-pane label="登录日志" name="first" :disabled="!Auth.includes('list_login')">
                <LoginLogs />
            </el-tab-pane>
            <el-tab-pane label="数据操作日志" name="second" :disabled="!Auth.includes('list_message')">
                <DataLogs />
            </el-tab-pane>
            <el-tab-pane label="系统操作日志" name="third" :disabled="!Auth.includes('list_operate')">
                <SystemLogs />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
export default {
    name: "importAuditLogs",
};
</script>


<script setup>
import { ref } from 'vue'
import LoginLogs from './components/LoginLogs'
import DataLogs from './components/DataLogs'
import SystemLogs from './components/SystemLogs'
import { useCache } from '@/hooks/useCache'

const activeName = ref('')


const { wsCache } = useCache()
const Auth = wsCache.get('Auth')
</script>

<style scoped>
:deep(.el-tabs__nav-wrap::after){
    background-color: #fff !important;
}
.demo-tabs > .el-tabs__content {
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
}
</style>

