<template>
  <div class="check">
    <div class="title">
      <div class="title_left">
        <div class="flex">
          <div class="flexBox">
            <div class="flexTitle">用户名称</div>
            <el-input v-model="search_param.operator"></el-input>
          </div>
          <div class="flexBox" style="flex: 2; margin-right: 80px">
            <div class="flexTitle" style="width: 110px">登录日期</div>
            <el-date-picker
              v-model="search_param.time"
              type="datetimerange"
              range-separator="~"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              style="width: 100%"
            />
          </div>
          <div class="flexBox" />
        </div>
      </div>
      <div class="title_right">
        <el-button
          v-debounce
          type="primary"
          style="margin-left: auto"
          @click="getList"
          >查询</el-button
        >
        <el-button v-debounce @click="reset">重置</el-button>
      </div>
    </div>
    <el-table
      :data="tableData"
      :border="1"
      :header-cell-style="{ 'background-color': '#f6f6f6' }"
      :cell-style="{ fontSize: '12px' }"
      style="width: 100%; height: calc(100vh - 250px)"
      @header-dragend="headerDragend"
      @selection-change="handleSelectionChange1"
      @row-click="rowClick"
    >
      <el-table-column type="selection" label="序号" width="55" />
      <el-table-column label="姓名" min-width="80px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.operator || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="时间" min-width="80px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.time || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="IP" min-width="80px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.ip || "暂无" }}</div>
        </template>
      </el-table-column>
    </el-table>
    <div class="demo-pagination-block">
      <el-pagination
        v-model:current-page="currentPage"
        :page-sizes="[10, 30, 50, 100, 500]"
        v-model:page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from "vue";
import { list_login } from "@/api/bank/rights.js";
import { ElMessage } from "element-plus";

//查询参数对象
const search_param = reactive({
  operator: "",
  time: [],
  page: 1,
  size: 10,
});

// 重置功能
const reset = () => {
  (search_param.operator = ""), (search_param.time = []);
};

//分页逻辑
const currentPage = ref(1);
const pageSize = ref(10);
const totalCount = ref(0);
const handleCurrentChange = (val) => {
  currentPage.value = val;
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};
const handleSizeChange = (val) => {
  pageSize.value = val;
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};

// 表格数据
let tableData = ref([]);
let multipleSelection1 = ref([]);
const handleSelectionChange1 = (val) => {
  multipleSelection1.value = val;
};
//获得数据列表
const getList = ({ cP = 1, size = 10, tips = false }) => {
  currentPage.value = cP;
  pageSize.value = size;
  list_login({
    operator: search_param.operator,
    time: search_param.time,
    page: cP,
    size: size,
  }).then((res) => {
    if (res.code == 200) {
      tableData.value = res.data.list;
      if (!tips)
        ElMessage({
          grouping: true,
          showClose: true,
          message: res.msg,
          type: "success",
        });
    }
    totalCount.value = res.data.count;
  });
};

const headerDragend = (row, column, cell) => {
  if (row < cell.minWidth) cell.width = cell.minWidth;
};
</script>

<style scoped>
.check {
  color: #666;
  font-size: 14px;
}
.title {
  display: flex;
}
.title_left {
  flex: 1;
}
.title_right {
  float: right;
}
.flex {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.flexBox {
  flex: 1;
  display: flex;
  line-height: 30px;
  text-align: right;
  margin-right: 50px;
}
.flexTitle {
  width: 110px;
  text-align: center;
}
.tableHeader {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}
/*分页样式 */
.demo-pagination-block {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
